import React, { useContext, useEffect, useState } from "react";
import { Page } from "../../components/Page";
import { BodyLightText } from "../../components/UI/Text";
import { Mark } from "../../components/UI/Mark";
import { Chat } from "../../components/Chat";
import { IChatInfo } from "../../models/ISupport";
import { useAppSelector } from "../../app/hooks";
import Support from "../../services/SuppotrService";
import { languageConstants } from "../../features/settings/settingsSlice";
import { ParamContext } from "../../context/paramReducer";
import { useCallback } from "react";

type PageSupportProps = {

};


export const PageSupport: React.FC<PageSupportProps> = () => {
  const { lang, setLoader } = useContext(ParamContext)
  const [chatsInfo, setChatsInfo] = useState<IChatInfo[]>([])
  const langConstants = useAppSelector(languageConstants)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [supportMessageNumber, setSupportMessageNumber] = useState(0)
  const infoString = langConstants["L_OPERATING_TIME_DAILY"]

  const infoStringStart = infoString && infoString.replace(/<b>.*/, "")
  const infoStringEnd = infoString && infoString.replace(/.*<\/b>/, "")
  const infoStringMeddle = infoString && infoString.replace(/.*<b>/, "").replace(/<\/b>.*/, "").replace(/(:start)/, "10:00").replace(/(:end)/, "22:00")

  const getIdChats = useCallback(() => {
    Support.getIdChats(10, 0, "true", lang)
      .then(res => {
        setChatsInfo(res.data.data)
        setLoaded(true)
        setLoader(false)
        setSupportMessageNumber(res.data.newSupportMessagesNumber)
      })
  }, [setLoader, lang])

  useEffect(() => {
    setLoader(true)
    getIdChats()
    const getIdCatsInterval = setInterval(() => {
      getIdChats()
    }, 10000);
    return () => {
      clearInterval(getIdCatsInterval)
      setLoader(false)
    }
  }, [getIdChats, setLoader])

  return (
    <>
      {loaded &&
        <Page currentPage={1} title={langConstants["L_CHAT"]} news={false}
          lead={
            <>
            <BodyLightText>{infoStringStart}<Mark mark="primary">{infoStringMeddle}</Mark>{infoStringEnd}</BodyLightText>
            <BodyLightText>{langConstants["L_FOR_QUESTION"]} <a href="mailto:support@matbea.com"><Mark mark="primary">support@matbea.com</Mark></a></BodyLightText>
            </>
          }
          countInPage={{ value: "10", label: 10 }}
        >
          <Chat chatsInfo={chatsInfo} supportMessageNumber={supportMessageNumber} />
        </Page>
      }
    </>
  )
};