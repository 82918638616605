import React, { useContext, useEffect, useRef, useState } from "react";
import "./header.scss";
import classNames from "classnames";
import { Logo } from "../Logo/Logo";
import { Container } from "../Grid/Container/Container";
import { Hamburger } from "../UI/Hamburger";
import { ButtonDanger, ButtonOutline } from "../UI/Button";
import { ButtonText } from "../UI/Text";
import { IconExit, IconUser } from "../UI/Icon";
import { Langs } from "../Langs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { languageConstants, updateCountry } from "../../features/settings/settingsSlice";
import { changeNickname } from "../../features/auth/authSlice";
import { ParamContext } from "../../context/paramReducer";
import SettingService from "../../services/settingService";
import { BaseCurrency } from "../BaseCurrency/BaseCurrency";
import { changeBaseCurrency } from "../../features/requisit/paySystemsSlice";
import Auth from "../../services/AuthService";
import { API_URL } from "../../api";

type HeaderProps = {
  getLanguage: (lang: "en" | "ru") => void;
};

export const Header: React.FC<HeaderProps> = ({getLanguage}) => {
  const { lang, setLang, setLoader } = useContext(ParamContext);
  const langConstants = useAppSelector(languageConstants);
  const [afk, setAfk] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const willMount = useRef(true);
  const [openActions, setActions] = useState<boolean>(false)

  const openActionsHandler = () => {
    setActions(!openActions);
  };

  useEffect(() => {
    SettingService.getSettings(lang)
      .then(res => {
        if (res.data.type === "success") {
          setAfk(res.data.data.merchantSettings.isAfk)
          dispatch(changeNickname(res.data.data.firstName))
          dispatch(changeBaseCurrency(res.data.data.baseCurrencyObj))
          if (willMount.current) {
            if (lang !== res.data.data.lang) setLang(res.data.data.lang)
            else getLanguage(lang)
            willMount.current = false
          }
          dispatch(updateCountry(res.data.data.baseCountry))
        }
      })
  }, [dispatch, setLang, lang, getLanguage])

  const changeAfk = () => {
    SettingService.changeAfk(afk, lang)
      .then(res => {
        if (res.data.type === "success") {
          setAfk(!afk)
        }
      })
  }

  const logoutHandler = () => {
    setLoader(true)
    Auth.logout()
      .then(res => {
        if (res.data.type === "success") {
          localStorage.clear();
          window.location.href = API_URL
        }
      })
  }

  return (
    <header className="header">
      <Container>
        <div className="header__body">
          <div className="header__left">
            <div className="header__logo">
              <Hamburger />
              <Logo />
            </div>
          </div>
          {Object.keys(langConstants).length > 0 &&
            <div className="header__right">
              <div className={`header__actions ${classNames(
                openActions ? "header__actions--open" : ""
              )}`}>
                <div className="header__actions-body">
                  <div className="header__actions-group">
                    <div className="header__actions-choice">
                      <div className="header__langs">
                        <Langs lang={lang} />
                      </div>
                      <div className="header__base-currency">
                        <BaseCurrency />
                      </div>
                    </div>

                    <div className="header__actions-afk">
                      {afk ?
                        <ButtonDanger size="md" onClick={changeAfk}>
                          <ButtonText>{langConstants["L_AFK_OFF"]}</ButtonText>
                        </ButtonDanger>
                        :
                        <ButtonOutline size="md" onClick={changeAfk}>
                          <ButtonText>{langConstants["L_AFK_ON"]}</ButtonText>
                        </ButtonOutline>
                      }
                    </div>
                  </div>

                  <div className="header__actions-user">
                    <ButtonOutline size="md" onClick={logoutHandler}>
                      <ButtonText>{langConstants["L_LOGOUT"]}</ButtonText>
                      <IconExit />
                    </ButtonOutline>
                  </div>
                </div>
              </div>

              <div className="header__user">
                <ButtonOutline size="md" onClick={openActionsHandler}>
                  <IconUser />
                </ButtonOutline>
              </div>
            </div>
          }
        </div>
      </Container>
    </header >
  );
};
