import React, { useContext } from "react";
import "./hamburger.scss";
import classNames from "classnames";
import { ParamContext } from "../../../context/paramReducer";

type HamburgerProps = {

};


export const Hamburger: React.FC<HamburgerProps> = () => {
  const {sideBarOpen, setSideBarOpen} = useContext(ParamContext)

  const openSideBarHandler = () => {
    setSideBarOpen(!sideBarOpen)
  };

  return (
    <div className={`hamburger ${classNames(sideBarOpen ? "hamburger--active" : "")}`} onClick={openSideBarHandler}>
      <div className="hamburger__body">
        <span className="hamburger__band"></span>
      </div>
    </div>
  );
};
