import React, { useState } from "react";
import "./bids.scss";
import classNames from "classnames";
import {
  ButtonText,
  CardText,
  CardBoldText,
  BodySmallText,
  BodyText,
  StatusText,
  TitleCardText,
} from "../UI/Text";
import {
  ButtonBase,
  ButtonDangerSecondary,
  ButtonFlatSecondary,
  ButtonSecondary,
} from "../UI/Button";
import {
  IconBasicTrashFull,
  IconChat,
  IconCheck,
  IconEdit,
  IconHelp,
  IconOutlineReport,
} from "../UI/Icon";
import { UserInfoCard, UserInfoCardItem } from "../UserInfoCard";
import { IMyApplicationData } from "../../models/IMyApplication";
import Services from "../../services/Services";
import { IPaySystemShort } from "../../models/IPaySystem";
import { useAppSelector } from "../../app/hooks";
import { paySystemFields } from "../../features/requisit/paySystemsSlice";
import { authNickname } from "../../features/auth/authSlice";
import useModal from "../../hooks/useModal";
import { ModalChangeAmount } from "../Modal/ModalChangeAmount";
import { languageConstants } from "../../features/settings/settingsSlice";
import { ICurrency } from "../../models/ICurrency";
import { ModalConfirmAction } from "../Modal/ModalConfirmAction";
import { ModalDelAction } from "../Modal/ModalDelAction";
import { ModalPayAction } from "../Modal/ModalPayAction/ModalPayAction";
import { IconText } from "../UI/IconText";
import { OperationState } from "../OperationState";
import { DropdownActions } from "../DropdownActions";
import { ChatShort } from "../Chat";
import { ModalComplaintAction } from "../Modal/ModalComplaintAction";
import { CounterRound } from "../UI/CounterRound";
import { ModalChangeClientName } from "../Modal/ModalChangeClientName";
import { Tooltips } from "../UI/Tooltips";

type BidsProps = {
  data: IMyApplicationData;
  currency: ICurrency;
  paySystem: IPaySystemShort;
  getData: () => void;
  numberMessages: number;
  order?: IMyApplicationData["order"];
  phoneInfo: {
    phoneNumber: number;
    fio: string;
    usersPhoneId: number;
    paymentsSystemId: number;
    requiredPhone?: boolean;
  };
};

type actionType = "pay" | "confirm" | "delete" | "cancel";

type MsgProps = {
  action: actionType;
  closeToast: () => void;
  actionToOrder: (e: actionType) => void;
};

export const Msg: React.FC<MsgProps> = ({
  action,
  closeToast,
  actionToOrder,
}) => {
  const langConstants = useAppSelector(languageConstants);


  let message = langConstants["L_REMIT_PAYMENT"];
  if (action === "confirm") message = langConstants["L_CONFIRM_PAYMENT"];
  if (action === "delete") message = langConstants["L_DEL_APPLICATION"];
  if (action === "cancel") message = langConstants["L_CANCEL_TRANSACTION"];
  const approveHandler = () => {
    actionToOrder(action);
  };
  return (
    <div className="bids__msg">
      <BodyText>{langConstants["L_CONFIRM_ACTION"] + ":" + message}</BodyText>
      <div className="bids__msg-buttons">
        <ButtonBase onClick={closeToast}>
          <ButtonText>{langConstants["L_CANCEL_VERB"]}</ButtonText>
        </ButtonBase>
        <ButtonBase onClick={approveHandler}>
          <ButtonText>{langConstants["L_CONFIRM"]}</ButtonText>
        </ButtonBase>
      </div>
    </div>
  );
};

export const Bids: React.FC<BidsProps> = ({
  data,
  currency,
  paySystem,
  getData,
  numberMessages,
  phoneInfo,
  order,
}) => {
  const [handlerOpenChat, setHandlerOpenChat] = useState(false);
  const dataCreate = Services.getFormatShortData(data.order.timeCreate);
  const dataUpdate = Services.getFormatShortData(data.order.timeUpdate);
  const allFieldsForPaySystems = useAppSelector(paySystemFields);
  const fields =
    allFieldsForPaySystems &&
    allFieldsForPaySystems[data.order.paymentSystemId];
  const langConstants = useAppSelector(languageConstants);

    console.log(langConstants)

  const nickName = useAppSelector(authNickname);

  const totalComms = data.order.commsTrusted + data.order.comms;
  let totalCommission = Services.getCurrencyFormat(
    Math.abs(totalComms),
    currency.prec
  );
  const totalPercent = data.order.commissionsSettings.hasOwnProperty(
    "totalPercent"
  )
    ? data.order.commissionsSettings.totalPercent
    : null;
  const totalCommsResult = {
    info: totalCommission + " " + currency.shortName,
    status: langConstants["L_COMMISSION"],
  };
  if (totalComms < 0)
    totalCommsResult.status = langConstants["L_OPERATION_BONUS"];
  if (totalPercent)
    totalCommsResult.info =
      Math.abs(totalPercent) +
      "% (" +
      totalCommission +
      " " +
      currency.shortName +
      ")";

  const [requisiteModalOpen] = useModal(
    <ModalChangeAmount
      isOpen={false}
      id={data.order.id.toString()}
      title={langConstants["L_CHANGE_AMOUNT_OF_ORDER"]}
      size="sm"
      nickName={nickName}
      getData={getData}
    />,
    true,
    false
  );

  const [payActionModalOpen] = useModal(
    <ModalPayAction
      isOpen={false}
      id={data.order.id.toString()}
      title={langConstants["L_CONFIRMATION"]}
      size="sm"
      getData={getData}
    />,
    true,
    false
  );

  const [delActionModalOpen] = useModal(
    <ModalDelAction
      isOpen={false}
      id={data.order.id.toString()}
      title={langConstants["L_CONFIRMATION"]}
      size="sm"
      getData={getData}
    />,
    true,
    false
  );

  const [confirmActionModalOpen] = useModal(
    <ModalConfirmAction
      isOpen={false}
      id={data.order.id.toString()}
      title={langConstants["L_CONFIRMATION"]}
      size="sm"
      getData={getData}
    />,
    true,
    false
  );

  const [complaintActionModalOpen] = useModal(
    <ModalComplaintAction
      isOpen={false}
      id={data.order.id.toString()}
      title={langConstants["L_COMPLAINT"]}
      size="sm"
      getData={getData}
    />,
    true,
    false
  );
  const [changeClientNameModalOpen] = useModal(
    <ModalChangeClientName
      isOpen={false}
      fio={phoneInfo.fio}
      usersPhoneId={phoneInfo?.usersPhoneId}
      paymentsSystemId={phoneInfo?.paymentsSystemId}
      orderId={data.order.id}
      title={langConstants["L_EDIT"]}
      size="sm"
      getData={getData}
    />,
    true,
    false
  );

  const onClickOpenChat = () => {
    setHandlerOpenChat(!handlerOpenChat);
  };

  const orderPayHandler = () => {
    payActionModalOpen();
  };
  const orderConfirmHandler = () => {
    confirmActionModalOpen();
  };
  const orderDelHandler = () => {
    delActionModalOpen();
  };
  const complaintHandler = () => {
    complaintActionModalOpen();
  };
  const formatNumberWithSpaces = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };
  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    let result = "";
    if (hours > 0) {
      result += hours + langConstants["L_DATE_H"] + " ";
    }
    if (minutes > 0) {
      result += minutes + langConstants["L_DATE_M"];
    }

    return result;
  };

  const getFormatValue = (value: string, id: number) => {
    if (id === 2) {
      return Services.formatCreditCardNumber(value);
    }
    if (id === 5) {
      return Services.formatPhoneNumber(value);
    }
    return value;
  };

  return (
    <OperationState state={data.order.type}>
      <div className="bids">
        <div className="bids__top">
          <div className="bids__info">
            <TitleCardText>{data.order.id}</TitleCardText>

            <div className="bids__info-time">
              <BodySmallText color="secondary">
                {`${langConstants["L_CREATED_F"]}:`}{" "}
                <span className="bids__info-time-date">
                  {dataCreate.time} {dataCreate.day}
                </span>
              </BodySmallText>
              <BodySmallText color="secondary">
                {`${langConstants["L_UPDATED_F"]}:`}{" "}
                <span className="bids__info-time-date">
                  {dataUpdate.time} {dataUpdate.day}
                </span>
              </BodySmallText>
            </div>
          </div>
          {(data.canChangeAmount || data.canComplaint) && (
            <DropdownActions>
              {data.canChangeAmount && (
                <ButtonFlatSecondary onClick={requisiteModalOpen}>
                  <IconEdit />
                  <ButtonText>{langConstants["L_EDIT"]}</ButtonText>
                </ButtonFlatSecondary>
              )}
              {data.canComplaint && (
                <ButtonFlatSecondary onClick={complaintHandler}>
                  <IconOutlineReport />
                  <ButtonText>{langConstants["L_COMPLAIN"]}</ButtonText>
                </ButtonFlatSecondary>
              )}
            </DropdownActions>
          )}
          {data.order.newAmount ? (
            <div className="bids__info-item">
              <StatusText color="red">
                {langConstants["L_WAITING_FOR_CHANGE_AMOUNT_ON"] +
                  " " +
                  data.order.newAmount +
                  " " +
                  currency.shortName}
              </StatusText>
              <StatusText>
                {langConstants["L_FROM_S"] +
                  " " +
                  data.order.amount +
                  " " +
                  currency.shortName}
              </StatusText>
            </div>
          ) : null}
        </div>
        <div className="bids__body">
          <div className="bids__body-col">
            <div className="bids__info-list-title">
              <CardText color="secondary">{langConstants["L_INFO"]}</CardText>
            </div>
            <div className="bids__info-list">
              <div className="bids__info-item">
                <BidsInfo
                  name={langConstants["L_CLIENT"] + ":"}
                  value={`${data.client.id}`}
                />

                <BidsInfo
                  name={langConstants["L_AMOUNT"] + ":"}
                  value={`${formatNumberWithSpaces(data.order.amount)} ${
                    currency.shortName
                  }`}
                />
              </div>

              <div className="bids__info-item">
                <BidsInfo
                  name={langConstants["L_PS_DETAILS_FIELD_53"] + ":"}
                  value={paySystem.name}
                />
                <BidsInfo
                  name={totalCommsResult.status + ":"}
                  value={totalCommsResult.info}
                />
              </div>

              <div className="bids__info-item bids__info-item--mob">
                <BidsInfo
                  name={langConstants["L_UPDATED_F"] + ":"}
                  value={`${dataUpdate.time} ${dataUpdate.day}`}
                />
                <BidsInfo
                  name={langConstants["L_CREATED_F"] + ":"}
                  value={`${dataCreate.time} ${dataCreate.day}`}
                />
              </div>
            </div>
          </div>

          <div className="bids__body-col"> 
            <div className="bids__info-list-title">
              <CardText color="secondary">
                {langConstants["L_MY_ESSENTIAL_ELEMENTS"]}
              </CardText>
            </div>
            {order?.type === 2 && <>
                          <div className="bids__info-list">
              <div className="bids__info-item">
                {order?.withdrawDetails?.["5"] && (
                  <BidsInfo
                    name={langConstants["L_PHONE"] + ":"}
                    value={`${order.withdrawDetails["5"]}`}
                  />
                )}
                {order?.withdrawDetails?.["53"] && (
                  <BidsInfo
                    name={langConstants["L_PS_DETAILS_FIELD_53"] + ":"}
                    value={order.withdrawDetails["53"]}
                  />
                )}
                {order?.withdrawDetails?.["47"] && (
                  <BidsInfo
                    name={langConstants["L_PS_DETAILS_FIELD_47"] + ":"}
                    value={order.withdrawDetails["47"]}
                  />
                )}
                {order?.withdrawDetails?.["2"] && (
                  <BidsInfo
                    name={langConstants["L_PS_DETAILS_FIELD_2"] + ":"}
                    value={order.withdrawDetails["2"]}
                  />
                )}
              </div>
            </div>

            <UserInfoCard>
              {fields.map((el) => {
                if (
                  data.order.depositDetails[el.fieldId]
                ) {
                  return (
                    <UserInfoCardItem
                      key={el.fieldId}
                      name={langConstants[`L_PS_DETAILS_FIELD_${el.fieldId}`]}
                      value={getFormatValue(
                        data.order.depositDetails[el.fieldId],
                        el.fieldId
                      )}
                    />
                  );
                } 
                return null
              })}
            </UserInfoCard>

            </>}
          </div>

          <div className="bids__body-col">
            <div className="bids__info-list-title">
              <CardText color="secondary">
                {langConstants["L_COUNTERAGENT"]}
              </CardText>
            </div>

            <UserInfoCard>
              <UserInfoCardItem
                name={langConstants["L_FIO"]}
                value={data.client.lastName + " " + data.client.middleName}
              />
              <UserInfoCardItem
                name={langConstants["L_STATUS"]}
                value={
                  data.client.passport.isApprove
                    ? langConstants["L_VERIFIED"]
                    : langConstants["L_NOT_VERIFIED"]
                }
              />
              <UserInfoCardItem
                name={langConstants["L_COUNTRY"]}
                value={data.client.countryName}
              />
              {Object.keys(data.order.withdrawDetails).length > 0 &&
                data.order.type === 1 && (
                  <CardText color="primary">
                    {langConstants["L_TRANSFER"] +
                      ` ${data.order.amount} ${currency.shortName} ` +
                      langConstants["L_TO"]}
                  </CardText>
                )}
              {data.order.type === 1 &&
                Object.entries(data.order.withdrawDetails).map((el) => (
                  <UserInfoCardItem
                    key={el[0]}
                    name={langConstants[`L_PS_DETAILS_FIELD_${el[0]}`]}
                    value={getFormatValue(el[1], +el[0])}
                  />
                ))}
              {data.order.type === 2 &&
                Object.entries(data.order.depositDetails).map((el) => (
                  <UserInfoCardItem
                    key={el[0]}
                    name={langConstants[`L_PS_DETAILS_FIELD_${el[0]}`]}
                    value={getFormatValue(el[1], +el[0])}
                  />
                ))}
              {/* {fields.map((el) => {
                console.log(">>>el", el);
                
                if (
                  data.order.type === 1 &&
                  data.order.withdrawDetails[el.fieldId]
                ) {
                  return (
                    <UserInfoCardItem
                      key={el.fieldId}
                      name={langConstants[`L_PS_DETAILS_FIELD_${el.fieldId}`]}
                      value={data.order.withdrawDetails[el.fieldId]}
                    />
                  );
                } else if (
                  data.order.type === 2 &&
                  data.order.depositDetails[el.fieldId]
                ) {
                  return (
                    <UserInfoCardItem
                      key={el.fieldId}
                      name={langConstants[`L_PS_DETAILS_FIELD_${el.fieldId}`]}
                      value={data.order.depositDetails[el.fieldId]}
                    />
                  );
                } else {
                  return null;
                }
              })} */}
            </UserInfoCard>
          </div>
        </div>
        {phoneInfo.requiredPhone && data.order.type === 2 && (
          <div className="bids__info-actions">
            <div className="bids__groups">
              <CardText color="secondary">
                {langConstants["L_PHONE_NUMBER"]}{" "}
                <Tooltips
                  content={langConstants["L_INFO_PHONE_TOLTIP"]}
                  placement="top"
                  key="10"
                >
                  <IconHelp size="md" />
                </Tooltips>
              </CardText>
              <div className="bids__info-number">
                {phoneInfo.phoneNumber !== 0 ? (
                  <>
                    <CardBoldText>
                      {Services.formatPhoneNumber(`${phoneInfo.phoneNumber}`)}{" "}
                      {phoneInfo.fio && phoneInfo.fio}
                    </CardBoldText>
                    <ButtonFlatSecondary onClick={changeClientNameModalOpen}>
                      <IconEdit />
                    </ButtonFlatSecondary>
                  </>
                ) : (
                  <CardBoldText>
                    {langConstants["L_WAITING_PHONE"]}
                  </CardBoldText>
                )}
              </div>
            </div>
            {/* <div className="bids__info-action-right">
              <Checkbox
                name="nameCheckbox"
                value=""
                label={langConstants["L_IN_WORK"]}
              />
            </div> */}
          </div>
        )}

        <div className="bids__bottom">
          <div className="bids__actions">
            <div className="bids__actions-left">
              <ButtonFlatSecondary onClick={onClickOpenChat}>
                <IconText gap="sm">
                  <ButtonText>{langConstants["L_OPEN_CHAT"]}</ButtonText>
                  <IconChat />
                  {numberMessages > 0 && (
                    <span className="bids__item-number-messages">
                      <CounterRound counter={numberMessages} />
                    </span>
                  )}
                </IconText>
              </ButtonFlatSecondary>
            </div>

            <div className="bids__actions-right">
              {data.needDisplayAutoCancelTimer && (
                <div className="bids__timer">
                  <BodySmallText tag="span">
                    {langConstants["L_TO_AUTO_CANCEL"]}:{" "}
                  </BodySmallText>
                  <BodySmallText tag="span">
                    {data.timeoutBeforeAutoCancel > 0
                      ? formatTime(data.timeoutBeforeAutoCancel)
                      : `0 ${langConstants["L_DATE_M"]}`}{" "}
                  </BodySmallText>
                </div>
              )}
              {data.needDisplayAutoComplaintTimer && (
                <div className="bids__timer">
                  <BodySmallText tag="span">
                    {langConstants["L_TO_COMPLAINT"]}:{" "}
                  </BodySmallText>
                  <BodySmallText tag="span">
                    {data.timeoutBeforeComplaint > 0
                      ? formatTime(data.timeoutBeforeComplaint)
                      : `0 ${langConstants["L_DATE_M"]}`}{" "}
                  </BodySmallText>
                </div>
              )}
              {data.needDisplayAutoConfirmTimer && (
                <div className="bids__timer">
                  <BodySmallText tag="span">
                    {langConstants["L_BEFORE_AUTO_CONFIRMATION"]}:{" "}
                  </BodySmallText>
                  <BodySmallText tag="span">
                    {data.timeoutBeforeAutoConfirm > 0
                      ? formatTime(data.timeoutBeforeAutoConfirm)
                      : `0 ${langConstants["L_DATE_M"]}`}{" "}
                  </BodySmallText>
                </div>
              )}
              {data.canPay && (
                <ButtonSecondary size="sm" onClick={orderPayHandler}>
                  <IconCheck />
                  <ButtonText>{langConstants["L_PAYED"]}</ButtonText>
                </ButtonSecondary>
              )}
              {data.canConfirm && (
                <ButtonSecondary size="sm" onClick={orderConfirmHandler}>
                  <IconCheck />
                  <ButtonText>{langConstants["L_CONFIRM"]}</ButtonText>
                </ButtonSecondary>
              )}
              {data.canDelete && (
                <ButtonDangerSecondary size="sm" onClick={orderDelHandler}>
                  <IconBasicTrashFull />
                  <ButtonText>{langConstants["L_DELETE"]}</ButtonText>
                </ButtonDangerSecondary>
              )}
            </div>
          </div>

          <div
            className={`bids__chat ${classNames(
              handlerOpenChat ? "bids__chat--open" : ""
            )}`}
          >
            {handlerOpenChat ? <ChatShort idChat={data.order.id} /> : null}
          </div>
        </div>
      </div>
    </OperationState>
  );
};

type BidsInfoProps = {
  name: string;
  value: string;
};

const BidsInfo: React.FC<BidsInfoProps> = ({ name, value }) => (
  <div className="bids-info">
    <CardText color="secondary">{name}</CardText>
    <CardBoldText>{value}</CardBoldText>
  </div>
);

type ListBidsProps = {
  children: React.ReactNode;
};

export const ListBids: React.FC<ListBidsProps> = ({ children }) => (
  <div className="bids-list">{children}</div>
);
