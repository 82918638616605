import React from "react";
import "./chat-list.scss";
import classNames from "classnames";
import { TitleChat, BodyText, ButtonText } from "../../../UI/Text";
import { useAppSelector } from "../../../../app/hooks";
import { languageConstants } from "../../../../features/settings/settingsSlice";
import { ButtonFlat } from "../../../UI/Button";
import Support from "../../../../services/SuppotrService";
import { IconFileBlank } from "../../../UI/Icon";

type ChatListProps = {
  date: string;
  children: React.ReactNode;
};


export const ChatList: React.FC<ChatListProps> = ({
  date,
  children,
}) => (
  <div className="chat-list">
    <div className="chat-list__title-date">
      <BodyText color="secondary">{date}</BodyText>
    </div>
    <ul className="chat-list__body">
      {children}
    </ul>
  </div>
);

type ChatListItemProps = {
  title: string;
  time: string;
  message: React.ReactNode;
  messageId: number;
  files: number[];
  support?: boolean;
  user?: boolean;
};


export const ChatListItem: React.FC<ChatListItemProps> = ({
  title,
  time,
  support = false,
  user = false,
  message,
  messageId,
  files
}) => {
  const langConstants = useAppSelector(languageConstants)

  const getDocument = (item: number) => {
    Support.getDoc(item, messageId)
      .then((res) => {
        const image = new Image();
        image.src = `data:image/jpeg;base64,${res.data}`
    const w = window.open("");
        if (w) {
          w.document.write(image.outerHTML);
          w.document.close();
        }
      })
  }

  return (
    <li className={`chat-list__item ${classNames(
      support ? "chat-list__item--support" : "",
      user ? "chat-list__item--user" : "",
    )}`}>
      <div className="chat-list__date">
        <TitleChat color="secondary" >{title}</TitleChat>
      </div>
      <div className="chat-list__message">
        <BodyText>{message}</BodyText>
        {files.length > 0 &&
          <div className="chat-list__files-list">
            {files.map(el => {
              const getDoc = () => getDocument(el)
              return (
                <ButtonFlat key={el} onClick={getDoc}>
                  <IconFileBlank></IconFileBlank>
                  <ButtonText>{langConstants["L_FILE"]}</ButtonText>
                </ButtonFlat>
              )
            })}
          </div>}
      </div>
      <div className="chat-list__time">
        <TitleChat color="secondary">{time}</TitleChat>
      </div>
    </li>
  )
};