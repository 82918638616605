import React from "react";
import "./status.scss";
import classNames from "classnames";
import { StatusText } from "../Text";

type StatusProps = {
  status?: StatusType;
};

type StatusType = "L_IN_PROGRESS" | "L_PERFORMED" | "L_HANDLING" | "L_TO_CANCEL" | "L_CANCELED" | "L_ORDER_STATUS_2" | "L_ORDER_STATUS_3" | "L_ORDER_STATUS_4" | "L_ORDER_STATUS_5" | "L_ORDER_STATUS_13" | "L_ORDER_STATUS_14";
// type StatusType = "done" | "processing" | "cancel" | "checked";

function getClass(status:StatusType) {
  if (status === "L_TO_CANCEL" || status === "L_CANCELED" || status === "L_ORDER_STATUS_5") return "cancel"
  else if (status === "L_PERFORMED" || status === "L_ORDER_STATUS_3") return "done"
  else if (status === "L_HANDLING") return "checked"
  else return "processing"
}

export const Status: React.FC<StatusProps> = ({
  status,
  children,
}) => {
  const className = status ? getClass(status) : ""
  return(
  <div className={`status ${classNames(
    status ? `status--${className}` : ""
  )}`}>
    <StatusText>{children}</StatusText>
  </div>
)};